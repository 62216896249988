import { useState } from "react";
import styled from "styled-components";
import SocialButton from "../general/SocialButton";
import { colors } from "../../assets/colors";
import { useSectionCursor } from "../general/Cursor";
import { useCursorHandlers } from "../general/Cursor";
import joinus from '../../assets/joinus.png';


const SectionContainer = styled.div`
  width: 100vw;
  height: 130vh;
  background-color: ${colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: -1px;
  max-width: 100%;
`;

const SubSectionContainer = styled.div`
  width: 100vw;
  height: 120vh;
  background-color: ${colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: -1px;
  max-width: 100%;
  
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;



const TitleText = styled.h1`
  display: inline-block;
  font-size: 16vmin;
  font-family: ReadexPro, sans-serif;
  margin: 0;
  padding-left: 20vh;
  @media (max-width: 450px) {
    padding-left: 0;
  }
  font-weight: 500;
  text-align: left;
  color: ${colors.red};
  @media only screen and (max-width: 700px) {
    font-size: 8vmin;
  }
`;

const JoinText = styled.h1`
    display: inline-block;
    font-size: 16vmin;
    font-family: ReadexPro, sans-serif;
    margin: 0;
    padding: 0;
    font-weight: 500;
    text-align: left;
    color: ${colors.white};
    @media only screen and (max-width: 700px) {
        font-size: 8vmin;
    }
`;

const BodyText = styled.p`
  display: inline-block;
  font-size: 3.2vmin;
  font-family: ReadexPro, Helvetica, sans-serif;
  padding-left: 20vh;
  line-height: 1.6;
  @media (max-width: 450px) {
    padding-left: 5vh;
    padding-right: 5vh;
    font-size: 2vmin;
    line-height: 1.4;
  }
  color: ${colors.white};
  font-weight: 300;
  @media only screen and (max-width: 700px) {
    font-size: 4.25vmin;
  }
`;

const Spacer = styled.div`
  width: 25%;
  height: 25vh;
  @media (max-width: 450px) {
    width: 100%;
    height: 5vh;
  }
`;

const ButtonSpacer = styled.div`
  height: 4vh;
`;

const NoticeSpacer = styled.div`
  height: 1.5vh;
`;

const LeftContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  
  @media (max-width: 450px) {
    align-items: center;
    padding-bottom: 20px;
  }
`;

const RightContentContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2vh;
  margin-right: 25vh;
  
  @media (max-width: 450px) {
    margin-right: 0;
  }
`;

// const BottomContentContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: between;
//   align-items: flex-start;
// `;

const ButtonBase = styled.button`
  width: 30vh;
  background: transparent;
  border: 1.5px solid;
  border-radius: 18px;
  color: ${colors.white};
  padding: 10px 20px;
  font-size: 2rem;
  transition: background-color 0.3s, color 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: none;
  &:hover {
    background-color: ${colors.white};
    color: ${colors.red};
    transform: translateY(-3px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.5);
  }
    
  @media screen and (max-width: 500px) {
    padding: 5px 10px;
    font-size: 1.75rem;
  }
`;

const FounderButton = styled(ButtonBase)`
  border-color: ${colors.red};
  color: ${colors.white};
  background-color: ${colors.red};
  &:hover {
    border-color: ${colors.red};
    background-color: ${colors.red};
    color: ${colors.white};
  }
  // text-decoration: line-through;
`;

const InternButton = styled(ButtonBase)`
  border-color: ${colors.white}; 
  color: ${colors.white}; 
  text-decoration: line-through;

  &:hover {
    background-color: ${colors.white}; 
    color: ${colors.red};
  }

  &:disabled {
    border-color: ${colors.darkGray};
    color: ${colors.gray}; 
    background-color: ${colors.darkGray}; 
  }
`;

const ApplicationNotice = styled.div`
  width: 30vh; 
  display: flex; 
  text-align: center;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
`;

const NoticeTitle = styled.h4`
  margin: 0; 
  padding-bottom: 5px;
  font-size: 0.9rem;
  font-family: ReadexPro, sans-serif;
  color: ${colors.darkGrayText};
  padding-left: 10px;
`;

const NoticeText = styled.p`
  margin: 0; 
  font-size: 0.9rem;
  // font-style: italic;
  font-family: ReadexPro, sans-serif;
  color: ${colors.red};
  // padding-left: 10px;
`;

const NoticeClosingText = styled.p`
  margin: 0; // Remove default margin
  font-size: 0.9rem;
  font-style: italic;
  font-family: ReadexPro, sans-serif;
  color: ${colors.red};
  padding-left: 10px;
`;

const ApplyText = styled.p`
    display: inline-block;
    border-bottom: 1.5px solid ${colors.white};
    padding-bottom: 4px;
    font-size: 2rem;
    font-family: ReadexPro, sans-serif;
    margin: auto;
    color: ${colors.white};
    @media only screen and (max-width: 700px) {
      font-size: 5vmin;
    }
`;

const BottomContentContainer = styled.div`
  width: 100%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: end;
	flex-wrap: wrap;
	bottom: 3vmax;
	right: 4.5vmax;
`;

const SocialContainer = styled.div`
  width: 40%;
  position: relative;
	display: flex;
	align-items: center;
	justify-content: end;
	flex-wrap: wrap;
`;

const Ellipsis = styled.div`
  font-size: 3rem;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: ". . .";
  }
  @media (max-width: 450px) {
    display: none;
  }
`;

const HashTagText = styled.p`
  width: 50%;
  text-align: start;
  position: relative;
  font-size: 2rem;
  font-family: ReadexPro, sans-serif;
  margin: auto;
  color: ${colors.red};
  @media only screen and (max-width: 700px) {
    font-size: 5vmin;
  }
`;

function JoinUs() {
  const cursorHandlers = useSectionCursor({color: colors.white});
  const normalCursorHandlers = useCursorHandlers();
  return (
    <SectionContainer {...cursorHandlers}>
      <SubSectionContainer>
        <LeftContentContainer>
            <TitleText><JoinText>JOIN</JoinText> US.</TitleText>
            <BodyText>
            This is a hands-on leadership opportunity where you will experience the challenges and rewards of hiring and managing a team in the early stages of a company. You will oversee meeting schedules, delegate tasks, and carry out your project roadmap.
            </BodyText>
            <BodyText>
            Gain over 1000 hours of product development and operational support in Momentum's 13-week accelerator program.
            </BodyText>
        </LeftContentContainer>
        <Spacer/>
        <RightContentContainer>
            <ApplyText>APPLY:</ApplyText>
            {/* <ButtonSpacer /> */}
            <FounderButton  {...normalCursorHandlers} onClick={() => window.open("https://linktr.ee/txmomentum", '_blank')}>Intern</FounderButton>
            {/* <NoticeSpacer /> */}
            
            {/* <ButtonSpacer /> */}
            <InternButton {...normalCursorHandlers} disabled onClick={() => window.open('https://forms.gle/WWX5iUt39AE4EEMr9', '_blank')}>Startup</InternButton>
            <NoticeSpacer />
            {/* <ApplicationNotice>
              <NoticeText>Fall 2024 Applications Closed!</NoticeText>
            </ApplicationNotice> */}
            <Ellipsis />
        </RightContentContainer>
        </SubSectionContainer>
          {/* <BottomContentContainer>
              <SocialContainer>
                <SocialButton name="Slack" color={colors.white} {...normalCursorHandlers}/>
                <SocialButton name="Instagram" color={colors.white} {...normalCursorHandlers}/>
                <SocialButton name="LinkedIn" color={colors.white} {...normalCursorHandlers}/>
                <SocialButton name="Email" color={colors.white} {...normalCursorHandlers}/>
              </SocialContainer>
          </BottomContentContainer> */}
    </SectionContainer>
  );

}

/* <div style={{position: 'relative'}}>
      <img src={joinus} alt="joinus" style={{width: '100%', height: 'auto', display: 'block', marginTop: '100vh'}} />
      <style>
        {`
          @media (max-width: 450px) {
            img {
              width: 100vw;
              height: 100vh;
              object-fit: cover;
              position: absolute;
              margin-top: 0 !important;
            }
          }
        `}
      </style>
    </div> */
export default JoinUs;