import { useState } from "react";
import styled from "styled-components";
import { colors } from "../../assets/colors";
import {
  useSectionCursor,
  useCursorHandlers,
  CursorContext,
} from "../general/Cursor";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import currentPortfolioInfo from "../../assets/currentPortfolioInfo";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { FaLinkedin } from "react-icons/fa"
import '../../styles.css';
import { EffectCards, EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import claySeifertHeadshot from '../../assets/headshots/ClaySeifert_Headshot.jpg';
import seyeonHanHeadshot from '../../assets/headshots/SeyeongHan_Headshot.jpg';
import aakritiKamalHeadshot from '../../assets/headshots/Aakriti_Headshot.png';
import adolfoGarcialHeadshot from '../../assets/headshots/Adolfo_Headshot.jpg'
import adityaPrasadHeadshot from '../../assets/headshots/Aditya_Headshot.jpeg';
import waltBoxwellHeadshot from '../../assets/headshots/WaltBoxwell_Headshot.png';
import annaFabrizioHeadshot from '../../assets/headshots/Anna Fabrizio Headshot.jpeg';
import tylerMcClintockHeadshot from '../../assets/headshots/TylerMcClintock_Headshot.jpg';
import shreyasSatewarHeadshot from '../../assets/headshots/ShreyasSatewar_Headshot.jpeg';
import coleNevinsHeadshot from '../../assets/headshots/ColeNevins_Headshot.jpg';
import milesNevinsHeadshot from '../../assets/headshots/MilesNevins_Headshot.jpg';
import joseYrigoyenHeadshot from '../../assets/headshots/JoseYrigoyen_Headshot.jpg';
import devRandalpuraHeadshot from '../../assets/headshots/DevRandalpura_Headshot.jpeg';
import rohanPatelHeadshot from '../../assets/headshots/RohanPatel_Headshot.jpg';
import shvetanKattaHeadshot from '../../assets/headshots/ShvetanKatta_Headshot.jpg';
import { LuArrowRightCircle, LuArrowLeftCircle, LuArrowUpCircle, LuArrowDownCircle } from "react-icons/lu";


const SectionContainer = styled.div`
  width: 100vw;
  min-height: 150vh;
  background-color: ${colors.white};
  display: flex;
  justify-content: start;

  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  position: sticky;
  flex-wrap: wrap;
  top: -1px;
  max-width: 100%;
  media only screen and (max-width: 700px) {
    min-height: 90vh;
  }
`;

const PresentCohortContainer = styled.div`
  margin-top: 25px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin: 50px;
  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

const PresentMobileCohortContainer = styled.div`
  height: 100%;
  display: flex;
  width: fit-content;
  padding: 5px;
  justify-content: center;
  @media only screen and (min-width: 975px) {
    display: none;
  }
`;
const TitleText = styled.h1`
  display: block;
  text-align: center;
  font-size: 4vmin;
  font-family: ReadexPro, sans-serif;
  font-weight: 500;
  color: ${colors.red};
  @media only screen and (max-width: 700px) {
    font-size: 6vmin;
  }
  text-transform: uppercase;
`;


const LogoContainer = styled.div`
  display: flex;
  font-size: 3.2vmin;
  font-family: ReadexPro, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  color: ${colors.black};
  line-height: 1.6;
  font-weight: 300;
  @media only screen and (max-width: 700px) {
    font-size: 4vmin;
  }
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const LogoImg = styled.img`
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
`;

const LogoImgMobile = styled.img`
  object-fit: contain;
  margin-left: 5px;
  align-items: center;
  width: 20%;
  height: 20%;
`;

const LogoCaption = styled.div`
  font-size: 3vmin;
  margin: 0;
  padding: 0;
  font-weight: 400;
  text-align: center;
  font-family: ReadexPro, sans-serif;
`;

const L = styled.div`
 display: flex;
 flex-direction: column;
 width: 100%;
 height: 100%;
 justify-content: space-evenly;
 align-items: center;
 
`;

const LogoImgContainer = styled.div`
  border-radius: 18px;
  background-color: #FFFFFF;
  display: flex;
  width: 95%;
  height: 75%;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    background-color: transparent;
    width: 50%;
    height: 50%;
    margin: 0;
  }
`;

const SwiperContainer = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;


const DescriptionContainer = styled.div`
  width: 53%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const SCContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 420px;
  background-color: rgb(240,240,240);
  border-radius: 20px;
`;

const InnerSCContainer = styled.div`
  padding: 0px 10px 0px 10px;
  border: 1.5px solid ${colors.white};
  background-color: ${colors.white};
  border-radius: 20px;
  width: 95%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InnerSCContainerAlt = styled.div`
  padding: 0px 10px 0px 10px;
  border: 1.5px solid ${colors.white};
  background-color: ${colors.white};
  border-radius: 20px;
  width: 95%;
  height: 90%;
  display: flex;
  flex-direction: row;
`;

const InnerSCContainerMobile = styled.div`
  padding: 5px 5px 5px 5px;
  border: 1.5px solid ${colors.white};
  background-color: ${colors.white};
  border-radius: 20px;
  width: 92%;
  height: 92%;
  flex-direction: column;
`;

const StartupTitle = styled.h1`
  font-size: 7vmin;
  font-weight: bold;
  font-family: ReadexPro, sans-serif;
  color: ${colors.black};
  text-align: center;
  letter-spacing: -3px;
  margin-bottom: 0px;
`;

const StartupTitleAlt = styled.h1`
  font-weight: bold;
  font-family: ReadexPro, sans-serif;
  color: ${colors.black};
  text-align: left;
  letter-spacing: -3px;
  margin-left: 30px;
  margin-bottom: 0px;
`;

const StartupTitleMobile = styled.h1`
  font-size: 7.5vmin;
  font-weight: bold;
  font-family: ReadexPro, sans-serif;
  color: ${colors.red};
  align-items: center;
  text-align: left;
  letter-spacing: -2px;
  margin-top: 5px;
  margin-bottom: 0px;
  margin-right: 5px;
`;

const StartupHeader = styled.h1`
  display: flex;
  justify-content: center;
  font-size: 4vmin;
  font-weight: 400;
  text-align: left;
  font-family: ReadexPro, sans-serif;
  color: ${colors.black};
`;

const TitleRowMobile = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${colors.red};
`;

const SCMobileContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 2px;
  width: 100%;
  height: 100%;
  color: ${colors.black};
`;

const Logo = ({ src, name, onClick}) => {
  const { setCursor } = useContext(CursorContext);

  const handleMouseEnter = () => setCursor({ active: true, color: "red" });
  const handleMouseLeave = () => setCursor({ active: false, color: "black" });
  return (
    <L onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <LogoImgContainer>
        <LogoImg src={src} />
      </LogoImgContainer>
      <LogoCaption >{ name }</LogoCaption>
    </L>
  );
};

const LogoMobile = ({ src }) => {

  return (
      <LogoImgMobile src={src} />
  );
};


const SwiperArrowsAndProgress = ({ children }) => {
  const { setCursor } = useContext(CursorContext);
  const handleMouseEnter = () => setCursor({ active: true, color:  `${colors.gray}` });
  const handleMouseLeave = () => setCursor({ active: false, color: `${colors.black}` });
  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-evenly' }}>
      {children}
    </div>
  );
};

const SwiperArrowsAndProgressTablet = ({ children }) => {
  const { setCursor } = useContext(CursorContext);
  const handleMouseEnter = () => setCursor({ active: true, color:  `${colors.gray}` });
  const handleMouseLeave = () => setCursor({ active: false, color: `${colors.black}` });
  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ marginLeft: '5px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
      {children}
    </div>
  );
};

const LinkedInIcon = ({ link }) => {
  const { setCursor } = useContext(CursorContext);
  const handleMouseEnter = () => setCursor({ active: true, color: "red" });
  const handleMouseLeave = () => setCursor({ active: false, color: `${colors.gray}` });
  return (
    <a style={{ fontSize: '1.1em', display: 'inline-flex', alignItems: 'center', marginLeft: '0.3em' }} onMouseEnter={handleMouseEnter} 
    onMouseLeave={handleMouseLeave} href={link} target="_blank" rel="noopener noreferrer">
      <FaLinkedin style={{ color: "black" }}
      onMouseOver={(e) => e.currentTarget.style.color = '#3b82f6'} 
      onMouseOut={(e) => e.currentTarget.style.color = colors.black}/>
    </a>
  );
};

const StartupContentMobileContainer = ({ company }) => {

  const normalCursorHandlers = useCursorHandlers();

  switch (company.name) {
    case "Resia":
      return (
        <SCMobileContainer>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px', justifyContent: 'space-evenly' }}>
              <div style={{ display: 'flex', textAlign: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                  <img 
                    src={claySeifertHeadshot} 
                    alt="Clay Seifert" 
                    style={{ marginTop: '5px', borderRadius: '25%', border: '2px solid red', width: '100px', height: '100px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
                  />
                  <h3 style={{ color: colors.black, marginTop: '0.3em', marginBottom: '0', textAlign: 'center', fontSize: '0.7em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  Clay Seifert <LinkedInIcon link="https://www.linkedin.com/in/clay-seifert/" />
                  </h3>
                  <p style={{ marginTop: '0', textAlign: 'center', fontSize: '0.7em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    CEO
                  </p>
                </div>
              </div>
              <div style={{ display: 'flex', textAlign: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                  <img 
                    src={seyeonHanHeadshot} 
                    alt="Seyeong Han" 
                    style={{ marginTop: '5px', borderRadius: '25%', border: '2px solid red', width: '100px', height: '100px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
                  />
                  <h3 style={{ color: colors.black, marginTop: '0.3em', marginBottom: '0', textAlign: 'center', fontSize: '0.7em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  Seyeong Han <LinkedInIcon link="https://www.linkedin.com/in/seyeong-han/" />
                  </h3>
                  <p style={{ marginTop: '0', textAlign: 'center', fontSize: '0.7em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    CTO
                  </p>
                </div>
              </div>
            </div>
            <p style={{ textAlign: 'center', marginTop: '0.3em', marginBottom: '0px', fontSize: '0.8em', fontWeight: '300', fontFamily: 'Merriweather, serif' }}>
            Resia helps contractors win more projects with instant designs and estimates that give homeowners confidence.
          </p>
          <p style={{textAlign: 'center',  marginTop: '0.3em', marginBottom: '0px' }}>
                <a style={{ fontSize: '0.65em', fontWeight: '300', fontFamily: 'Merriweather, serif', color: colors.red }} {...normalCursorHandlers} href="https://forms.gle/AaqN6NHxU2d2hga47" target="_blank" >
                View Intern Roles →
                </a></p>
        </SCMobileContainer>
      )
      case "Livegate":
        return (
          <SCMobileContainer>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px', justifyContent: 'space-evenly' }}>
                <div style={{ display: 'flex', textAlign: 'center' }}>
                  <div style={{ textAlign: 'center' }}>
                    <img 
                      src={adolfoGarcialHeadshot} 
                      alt="Adolfo Garcia" 
                      style={{ marginTop: '5px', borderRadius: '25%', border: '2px solid red', width: '100px', height: '100px', objectFit: 'cover', objectPosition: 'center', overflow: 'hidden' }} 
                    />
                    <h3 style={{ color: colors.black, marginTop: '0.3em', marginBottom: '0', textAlign: 'center', fontSize: '0.7em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    Adolfo Garcia <LinkedInIcon link="https://www.linkedin.com/in/adolfo-garcia-capetillo-324539266/" />
                    </h3>
                    <p style={{ marginTop: '0', textAlign: 'center', fontSize: '0.7em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      CEO
                    </p>
                  </div>
                </div>
                <div style={{ display: 'flex', textAlign: 'center' }}>
                  <div style={{ textAlign: 'center' }}>
                    <img 
                      src={adityaPrasadHeadshot} 
                      alt="Aditya Prasad" 
                      style={{ marginTop: '5px', borderRadius: '25%', border: '2px solid red', width: '100px', height: '100px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
                    />
                    <h3 style={{ color: colors.black, marginTop: '0.3em', marginBottom: '0', textAlign: 'center', fontSize: '0.7em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    Aditya Prasad <LinkedInIcon link="https://www.linkedin.com/in/aditya05prasad/" />
                    </h3>
                    <p style={{ marginTop: '0', textAlign: 'center', fontSize: '0.7em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      CTO
                    </p>
                  </div>
                </div>
              </div>
              <p style={{ textAlign: 'center', marginTop: '0.3em', marginBottom: '0px', fontSize: '0.8em', fontWeight: '300', fontFamily: 'Merriweather, serif' }}>
              Livegate is a live communications platform that lets people chat about sporting events as they're happening in real time.
              </p>
              <p style={{textAlign: 'center',  marginTop: '0.3em', marginBottom: '0px' }}>
              <a style={{ fontSize: '0.65em', fontWeight: '300', fontFamily: 'Merriweather, serif', color: colors.red }} {...normalCursorHandlers} href="https://forms.gle/JAqTKtWyf86kqVhf9" target="_blank" >
              View Intern Roles →
              </a></p>
          </SCMobileContainer>
        )
    case "CURA":
      return (
      <SCMobileContainer>
          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
            <div style={{ textAlign: 'center', marginTop: '5px' }}>
              <img 
                src={aakritiKamalHeadshot} 
                alt="Aakriti Kamal" 
                style={{ marginTop: '10px', borderRadius: '25%', border: '3px solid red',  width: '125px', height: '125px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
              />
              <h3 style={{ color: colors.black, marginTop: '0.3em', marginBottom: '0', textAlign: 'center', fontSize: '0.8em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              Aakriti Kamal <LinkedInIcon link="https://www.linkedin.com/in/aakriti-kamal/" />
              </h3>
              <p style={{ marginTop: '0', textAlign: 'center', fontSize: '0.8em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                CEO
              </p>
            </div>
          </div>  
          <p style={{textAlign: 'center',  marginTop: '0.3em', marginBottom: '0px', fontSize: '0.8em', fontWeight: '300', fontFamily: 'Merriweather, serif' }}>
          Cura is an AI-powered app providing tailored support for family caregivers of mentally ill.
          </p>
          <p style={{textAlign: 'center',  marginTop: '0.3em', marginBottom: '0px' }}>
          <a style={{ fontSize: '0.65em', fontWeight: '300', fontFamily: 'Merriweather, serif', color: colors.red }} {...normalCursorHandlers} href="https://forms.gle/uWn8diqRmXtEajDLA" target="_blank" >
          View Intern Roles →
          </a></p>

      </SCMobileContainer>
      )
    case "Suits":
      return (
        <SCMobileContainer>
          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
            <div style={{ textAlign: 'center', marginTop: '5px' }}>
              <img 
                src={shreyasSatewarHeadshot} 
                alt="Shreyas Satewar" 
                style={{ marginTop: '10px', borderRadius: '25%', border: '3px solid red',  width: '125px', height: '125px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
              />
              <h3 style={{ color: colors.black, marginTop: '0.3em', marginBottom: '0px', textAlign: 'center', fontSize: '0.8em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              Shreyas Satewar <LinkedInIcon link="https://www.linkedin.com/in/shreyas-satewar/" />
              </h3>
              <p style={{ marginTop: '0', textAlign: 'center', fontSize: '0.8em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                CEO
              </p>
              <p style={{ textAlign: 'center', marginTop: '0.3em', marginBottom: '0px', fontSize: '0.8em', fontWeight: '300', fontFamily: 'Merriweather, serif' }}>
              Suits enables users to join, create, and claim compensation from class action settlements.
              </p>
              <p style={{textAlign: 'center',  marginTop: '0.3em', marginBottom: '0px' }}>
              <a style={{ fontSize: '0.65em', fontWeight: '300', fontFamily: 'Merriweather, serif', color: colors.red }} {...normalCursorHandlers} href="https://forms.gle/tSaA1euatd5Jh7oQ9" target="_blank" >
              View Intern Roles →
              </a></p>
            </div>
          </div>
        </SCMobileContainer>
      )
  
    case "cacao.tax":
      return (
        <SCMobileContainer>
          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
            <div style={{ textAlign: 'center', marginTop: '5px' }}>
              <img 
                src={joseYrigoyenHeadshot} 
                alt="Jose Yrigoyen" 
                style={{ marginTop: '10px', borderRadius: '25%', border: '3px solid red',  width: '125px', height: '125px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
              />
              <h3 style={{ color: colors.black, marginTop: '0.3em', marginBottom: '0', textAlign: 'center', fontSize: '0.8em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              Jose Yrigoyen <LinkedInIcon link="https://www.linkedin.com/in/jmyrigoyen/" />
              </h3>
              <p style={{ marginTop: '0', textAlign: 'center', fontSize: '0.8em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                CEO
              </p>
              <p style={{ textAlign: 'center', marginTop: '0.3em', marginBottom: '0px', fontSize: '0.8em', fontWeight: '300', fontFamily: 'Merriweather, serif' }}>
              Bringing the tax-free benefit to international shoppers, starting with individual tax refunds.
              </p>
              <p style={{textAlign: 'center',  marginTop: '0.3em', marginBottom: '0px' }}>
              <a style={{ fontSize: '0.65em', fontWeight: '300', fontFamily: 'Merriweather, serif', color: colors.red }} {...normalCursorHandlers} href="https://forms.gle/cBD9jh8vRoxbisZk7" target="_blank" >
              View Intern Roles →
              </a></p>
            </div>
          </div>
        </SCMobileContainer>
      )
    // case "Thrive Education":
    //   return (
    //     <SCMobileContainer>
    //        <p style={{textAlign: 'center',  marginTop: '0.3em', marginBottom: '0px', fontSize: '0.55em', fontWeight: '300', fontFamily: 'Merriweather, serif' }}>
    //        Thrive Education personalizes diabetes education through AI and provider-patient interaction.
    //         </p>
    //         <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
    //           <div style={{ textAlign: 'center', marginTop: '2px' }}>
    //             <img 
    //               src={devRandalpuraHeadshot} 
    //               alt="Dev Randalpura" 
    //               style={{ marginTop: '5px', borderRadius: '50%', width: '80px', height: '80px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
    //             />
    //             <h3 style={{ color: colors.black, marginTop: '0.3em', textAlign: 'center', fontSize: '0.65em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //             Dev Randalpura <LinkedInIcon link="https://www.linkedin.com/in/dev-randalpura-8533a81b7/" />
    //             </h3>
    //           </div>
    //         </div>
    //         <div style={{ display: 'flex', flexDirection: 'row', marginTop: '-15px', justifyContent: 'space-evenly' }}>
    //           <div style={{ display: 'flex', textAlign: 'center' }}>
    //             <div style={{ textAlign: 'center' }}>
    //               <img 
    //                 src={rohanPatelHeadshot} 
    //                 alt="Rohan Patel" 
    //                 style={{ marginTop: '5px', borderRadius: '50%', width: '80px', height: '80px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
    //               />
    //               <h3 style={{ color: colors.black, marginTop: '0.3em', textAlign: 'center', fontSize: '0.65em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //               Rohan Patel <LinkedInIcon link="https://www.linkedin.com/in/rohan-r-patel/" />
    //               </h3>
    //             </div>
    //           </div>
    //           <div style={{ display: 'flex', textAlign: 'center' }}>
    //             <div style={{ textAlign: 'center' }}>
    //               <img 
    //                 src={shvetanKattaHeadshot} 
    //                 alt="Shvetan Katta" 
    //                 style={{ marginTop: '5px', borderRadius: '50%', width: '80px', height: '80px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
    //               />
    //               <h3 style={{ color: colors.black, marginTop: '0.3em', textAlign: 'center', fontSize: '0.65em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //               Shvetan Katta <LinkedInIcon link="https://www.linkedin.com/in/shvetan-katta/" />
    //               </h3>
    //             </div>
    //           </div>
    //         </div>
    //         <h2 style={{marginTop: '0px', textAlign: 'center', fontSize: '0.65em', fontWeight: '200', fontFamily: 'ReadexPro, sans-serif' }}>Internship Roles Available</h2>
    //         <div style={{  marginLeft: '15px', marginRight: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
    //           <div style={{ border: `1.2px solid ${colors.red}`, borderRadius: '20px', padding: '2px' }}>
    //             <h3 style={{ textAlign: 'center', fontSize: '0.65em', fontFamily: 'ReadexPro, sans-serif'  }}>Tech</h3>
    //             <ul style={{ marginTop: '-10px', fontSize: '0.55em', fontWeight: '200', fontFamily: 'Merriweather, serif' }}>
    //               <li>Develop and integrate AI features into the mobile app</li>
    //               <li>Create a data analytics dashboard (React Native with TypeScript, SQL)</li>
    //               <li>Collect and implement patient/clinician feedback</li>
    //             </ul>
    //           </div>
    //         </div>
    //     </SCMobileContainer>
    //     )
   }
};
const StartupContentContainer = ({ company }) => {
  const { setCursor } = useContext(CursorContext);
  const normalCursorHandlers = useCursorHandlers();
  const handleMouseEnter = () => setCursor({ active: false, color: `${colors.gray}` });
  const handleMouseLeave = () => setCursor({ active: false, color: "black" });

  switch (company.name) {
    case "Resia":
      return (
        <SCContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <InnerSCContainer>
            <div style={{ display: 'flex', flexDirection: 'row',}}>
              <div style={{ display: 'flex', textAlign: 'center', marginLeft: '20px', marginRight: '10px' }}>
                <div style={{ flex: '1', textAlign: 'center' }}>
                  <img 
                    src={claySeifertHeadshot} 
                    alt="Clay Seifert" 
                    style={{  marginTop: '10px', borderRadius: '25%', border: '3px solid red',  width: '145px', height: '145px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
                  />
                  <h3 style={{ marginTop: '0.3em', marginBottom: '0', textAlign: 'center', fontSize: '1em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  Clay Seifert <LinkedInIcon link="https://www.linkedin.com/in/clay-seifert/" />
                  </h3>
                  <p style={{ marginTop: '0', textAlign: 'center', fontSize: '0.9em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    CEO
                  </p>
                </div>
              </div>
              <div style={{ display: 'flex', textAlign: 'center', marginLeft: '10px', marginRight: '20px' }}>
                <div style={{ flex: '1', textAlign: 'center' }}>
                  <img 
                    src={seyeonHanHeadshot} 
                    alt="Seyeong Han" 
                    style={{  marginTop: '10px', borderRadius: '25%', border: '3px solid red',  width: '145px', height: '145px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
                  />
                  <h3 style={{ marginTop: '0.3em', marginBottom: '0', textAlign: 'center', fontSize: '1em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  Seyeong Han <LinkedInIcon link="https://www.linkedin.com/in/seyeong-han/" />
                  </h3>
                  <p style={{ marginTop: '0', textAlign: 'center', fontSize: '0.9em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    CTO
                  </p>
                </div>
              </div>
              <div style={{ flex: '1', textAlign: 'center' }}>
                <StartupTitle style={{ marginTop: '10px', marginBottom: '0em', color: colors.red }}> 
                  {company.name}
                </StartupTitle>
                <p style={{ marginTop: '0.3em', fontSize: '1em', fontWeight: '300', fontFamily: 'Merriweather, serif' }}>
                  Resia helps contractors win more projects with instant designs and estimates that give homeowners confidence.
                </p>
                <p style={{textAlign: 'center',  marginTop: '0.3em', marginBottom: '0px' }}>
                <a style={{ fontSize: '0.8em', fontWeight: '300', fontFamily: 'Merriweather, serif', color: colors.red }} {...normalCursorHandlers} href="https://forms.gle/AaqN6NHxU2d2hga47" target="_blank" >
                View Intern Roles →
                </a></p>
              </div>
            </div>
          </InnerSCContainer>
        </SCContainer>
      ); 
      case "Livegate":
        return (
          <SCContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <InnerSCContainer>
              <div style={{ display: 'flex', flexDirection: 'row',}}>
                <div style={{ display: 'flex', textAlign: 'center', marginLeft: '20px', marginRight: '10px' }}>
                  <div style={{ flex: '1', textAlign: 'center' }}>
                    <img 
                      src={adolfoGarcialHeadshot} 
                      alt="Adolfo Garcia" 
                      style={{  marginTop: '10px', borderRadius: '25%', border: '3px solid red',  width: '145px', height: '145px', objectFit: 'cover', objectPosition: 'center', overflow: 'hidden' }} 
                    />
                    <h3 style={{ marginTop: '0.3em', marginBottom: '0', textAlign: 'center', fontSize: '1em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    Adolfo Garcia <LinkedInIcon link="https://www.linkedin.com/in/adolfo-garcia-capetillo-324539266/" />
                    </h3>
                    <p style={{ marginTop: '0', textAlign: 'center', fontSize: '0.9em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      CEO
                    </p>
                  </div>
                </div>
                <div style={{ display: 'flex', textAlign: 'center', marginLeft: '10px', marginRight: '20px' }}>
                  <div style={{ flex: '1', textAlign: 'center' }}>
                    <img 
                      src={adityaPrasadHeadshot} 
                      alt="Aditya Prasad" 
                      style={{  marginTop: '10px', borderRadius: '25%', border: '3px solid red',  width: '145px', height: '145px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
                    />
                    <h3 style={{ marginTop: '0.3em', marginBottom: '0', textAlign: 'center', fontSize: '1em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    Aditya Prasad <LinkedInIcon link="https://www.linkedin.com/in/aditya05prasad/" />
                    </h3>
                    <p style={{ marginTop: '0', textAlign: 'center', fontSize: '0.9em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      CTO
                    </p>
                  </div>
                </div>
                <div style={{ flex: '1', textAlign: 'center' }}>
                  <StartupTitle style={{ marginTop: '10px', marginBottom: '0em', color: colors.red }}> 
                    {company.name}
                  </StartupTitle>
                  <p style={{ marginTop: '0.3em', fontSize: '1em', fontWeight: '300', fontFamily: 'Merriweather, serif' }}>
                  Livegate is a live communications platform that lets people chat about sporting events as they're happening in real time.
                  </p>
                  <p style={{textAlign: 'center',  marginTop: '0.3em', marginBottom: '0px' }}>
                  <a style={{ fontSize: '0.8em', fontWeight: '300', fontFamily: 'Merriweather, serif', color: colors.red }} {...normalCursorHandlers} href="https://forms.gle/JAqTKtWyf86kqVhf9" target="_blank" >
                  View Intern Roles →
                  </a></p>
                </div>
              </div>
            </InnerSCContainer>
          </SCContainer>
        );
    case "CURA":
      return (
        <SCContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <InnerSCContainer>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: '45%'}}>
                <div style={{ textAlign: 'center' }}>
                  <img 
                    src={aakritiKamalHeadshot} 
                    alt="Aakriti Kamal" 
                    style={{ marginTop: '10px', borderRadius: '25%', border: '3px solid red',  width: '145px', height: '145px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
                  />
                  <section style={{ display: 'flex', flexDirection: 'column', }}>
                  <p style={{ marginTop: '0.3em', marginBottom: '0', textAlign: 'center', fontSize: '1em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  Aakriti Kamal <LinkedInIcon link="https://www.linkedin.com/in/aakriti-kamal/" />
                  </p>
                  <p style={{ marginTop: '0', textAlign: 'center', fontSize: '0.9em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    CEO
                  </p>
                  </section>
                </div>
              </div>
              <div style={{  textAlign: 'end' }}>
                <StartupTitle style={{ marginTop: '10px', marginBottom: '0em', color: colors.red }}> 
                  {company.name}
                </StartupTitle>
                <p style={{ marginTop: '0.3em', fontSize: '1em', fontWeight: '300', fontFamily: 'Merriweather, serif' }}>
                Cura is an AI-powered app providing tailored support for family caregivers of mentally ill.
                </p>
                <p style={{textAlign: 'center',  marginTop: '0.3em', marginBottom: '0px' }}>
                <a style={{ fontSize: '0.8em', fontWeight: '300', fontFamily: 'Merriweather, serif', color: colors.red }} {...normalCursorHandlers} href="https://forms.gle/uWn8diqRmXtEajDLA" target="_blank" >
                View Intern Roles →
                </a></p>
              </div>
            </div>
          </InnerSCContainer>
        </SCContainer>
      );
    case "Suits":
      return (
        <SCContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <InnerSCContainer>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ margin: '0px'}}>
                <StartupTitleAlt style={{ fontSize: '7vmin', color: colors.red}}>{ company.name }</StartupTitleAlt>
                <p style={{ marginLeft: '30px', marginTop: '0.3em', fontSize: '1em', fontWeight: '300', fontFamily: 'Merriweather, serif' }}>
                Suits enables users to join, create, and claim compensation from class action settlements.
                </p>
                <p style={{textAlign: 'center',  marginTop: '0.3em', marginBottom: '0px' }}>
                <a style={{ fontSize: '0.8em', fontWeight: '300', fontFamily: 'Merriweather, serif', color: colors.red }} {...normalCursorHandlers} href="https://forms.gle/tSaA1euatd5Jh7oQ9" target="_blank" >
                View Intern Roles →
                </a></p>
              </div>
              <div style={{ width: '40%'}}>
                <div style={{ textAlign: 'center' }}>
                  <img 
                    src={shreyasSatewarHeadshot} 
                    alt="Shreyas Satewar" 
                    style={{ marginTop: '10px', borderRadius: '25%', border: '3px solid red',  width: '145px', height: '145px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
                  />
                   <h3 style={{ marginTop: '0.3em', marginBottom: '0', textAlign: 'center', fontSize: '1em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                   Shreyas Satewar <LinkedInIcon link="https://www.linkedin.com/in/shreyas-satewar/" />
                  </h3>
                  <p style={{ marginTop: '0', textAlign: 'center', fontSize: '0.9em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    CEO
                  </p>
                </div>
              </div>
            </div>
          </InnerSCContainer>
        </SCContainer>
      );
    case "cacao.tax":
      return (
        <SCContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <InnerSCContainer>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ margin: '0px'}}>
                <StartupTitleAlt style={{ fontSize: '7vmin', color: colors.red}}>{ company.name }</StartupTitleAlt>
                <p style={{ marginLeft: '30px', marginTop: '0.3em', fontSize: '1em', fontWeight: '300', fontFamily: 'Merriweather, serif' }}>
                Bringing the tax-free benefit to international shoppers, starting with individual tax refunds.
                </p>
                <p style={{textAlign: 'center',  marginTop: '0.3em', marginBottom: '0px' }}>
                <a style={{ fontSize: '0.8em', fontWeight: '300', fontFamily: 'Merriweather, serif', color: colors.red }} {...normalCursorHandlers} href="https://forms.gle/cBD9jh8vRoxbisZk7" target="_blank" >
                View Intern Roles →
                </a></p>
              </div>
              <div style={{ width: '35%'}}>
                <div style={{ textAlign: 'center' }}>
                  <img 
                    src={joseYrigoyenHeadshot} 
                    alt="Jose Yrigoyen" 
                    style={{ marginTop: '10px', borderRadius: '25%', border: '3px solid red',  width: '145px', height: '145px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
                  />
                  <h3 style={{ marginTop: '0.3em', marginBottom: '0', textAlign: 'center', fontSize: '1em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  Jose Yrigoyen <LinkedInIcon link="https://www.linkedin.com/in/jmyrigoyen/" />
                  </h3>
                  <p style={{ marginTop: '0', textAlign: 'center', fontSize: '0.9em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    CEO
                  </p>
                </div>
              </div>
            </div>
          </InnerSCContainer>
        </SCContainer>
      );
    // case "Thrive Education":
    //   return (
    //     <SCContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
    //       <InnerSCContainerAlt>
    //         <div style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
    //           <div style={{ margin: '0px'}}>
    //             <StartupTitleAlt style={{ fontSize: '6.25vmin'}}>{ company.name }</StartupTitleAlt>
    //             <p style={{ marginLeft: '30px', marginTop: '0.3em', fontSize: '0.77em', fontWeight: '300', fontFamily: 'Merriweather, serif' }}>
    //             Thrive Education personalizes diabetes education through AI and provider-patient interaction.
    //             </p>
    //           </div>
    //           <h2 style={{ marginTop: '0px', textAlign: 'center', fontSize: '1.2em', fontWeight: '200', fontFamily: 'ReadexPro, sans-serif' }}>Internship Roles Available</h2>
    //           <div style={{ marginLeft: '15px', marginRight: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
    //             <div style={{ border: `1.5px solid ${colors.red}`, borderRadius: '20px', }}>
    //               <h3 style={{ textAlign: 'center', fontSize: '1em', fontFamily: 'ReadexPro, sans-serif'  }}>Tech</h3>
    //               <ul style={{ marginTop: '-10px', fontSize: '0.8em', fontWeight: '200', fontFamily: 'Merriweather, serif', paddingRight: '1px' }}>
    //                 <li>Develop and integrate AI features into the mobile app</li>
    //                 <li>Create a data analytics dashboard (React Native with TypeScript, SQL)</li>
    //                 <li>Collect and implement patient/clinician feedback</li>
    //               </ul>
    //             </div>
    //           </div>
    //         </div>
    //         <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '30%', alignItems: 'center', height: '100%'}}>
    //           <div style={{ textAlign: 'center' }}>
    //             <img 
    //               src={devRandalpuraHeadshot} 
    //               alt="Dev Randalpura" 
    //               style={{ marginTop: '10px', borderRadius: '50%', width: '120px', height: '120px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
    //             />
    //             <h3 style={{ marginTop: '0.3em', textAlign: 'center', fontSize: '0.85em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //             Dev Randalpura <LinkedInIcon link="https://www.linkedin.com/in/dev-randalpura-8533a81b7/" />
    //           </h3>
    //           </div>
    //           <div style={{ textAlign: 'center' }}>
    //             <img 
    //               src={shvetanKattaHeadshot} 
    //               alt="Shvetan Katta" 
    //               style={{ marginTop: '10px', borderRadius: '50%', width: '120px', height: '120px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
    //             />
    //             <h3 style={{ marginTop: '0.3em', textAlign: 'center', fontSize: '0.85em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //             Shvetan Katta <LinkedInIcon link="https://www.linkedin.com/in/shvetan-katta/" />
    //           </h3>
    //           </div>
    //         </div>
    //         <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', height: '100%', alignItems: 'center' }}>
    //           <img 
    //             src={rohanPatelHeadshot} 
    //             alt="Rohan Patel" 
    //             style={{ marginTop: '10px', borderRadius: '50%', width: '120px', height: '120px', objectFit: 'cover', objectPosition: 'top', overflow: 'hidden' }} 
    //           />
    //           <h3 style={{ marginTop: '0.3em', textAlign: 'center', fontSize: '0.85em', fontWeight: '300', fontFamily: 'ReadexPro, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //             Rohan Patel <LinkedInIcon link="https://www.linkedin.com/in/rohan-r-patel/" />
    //           </h3>
    //         </div>
            
    //       </InnerSCContainerAlt>
    //     </SCContainer>
    //   );
  }
};



function CurrentComp() {
  const groupedCompanies = currentPortfolioInfo.reduce((acc, company) => {
    const { semester } = company;
    if (!acc[semester]) {
      acc[semester] = [];
    }
    acc[semester].push(company);
    return acc;
  }, {});

  const companies = Object.entries(groupedCompanies)[0][1]
  const [company, setCompany] = useState(companies[0]);

  useEffect(() => {
    const savedPosition = sessionStorage.getItem("scrollPosition");
    if (savedPosition !== null) {
      window.scrollTo({
        top: parseInt(savedPosition, 10),
        behavior: "instant",
      });
      sessionStorage.removeItem("scrollPosition");
    }
  }, []);

  const cursorHandlers = useSectionCursor({ color: colors.black });
  const navigate = useNavigate();

  const companyCursorHandlers = useCursorHandlers({
    hoverOptions: { type: "default", color: colors.red },
    defaultOptions: { type: "default", color: colors.black },
  });

  const handleCompanyClick = (company) => {
    // Navigate to the company page
    sessionStorage.setItem("scrollPosition", window.scrollY);
    navigate(`/portfolio/${company.name}`);
  };

  const handleSlideChange = (swiper) => {
    const currentIndex = swiper.activeIndex;
    const currentCompany = companies[currentIndex];
    setCompany(currentCompany);
  };


  return (
    <SectionContainer {...cursorHandlers}>
      <TitleText>Spring 2025 Cohort</TitleText>
      {Object.entries(groupedCompanies).map(([semester, companies]) => (
        <>
        <PresentCohortContainer key={semester}>
          <SwiperContainer>
            <Swiper
              effect={'cards'}
              grabCursor={true}
              spaceBetween={30}
              modules={[EffectCards, Navigation]}
              className="mySwiper"
              onSlideChange={handleSlideChange}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              pagination={{
                el: ".swiper-pagination",
                dynamicBullets: true,
              }}
            >
            {companies.map((company) => {
              return (
              <SwiperSlide key={company.name}>
                <Logo
                  key={company.name}
                  src={require(`../../assets/logos/${company.logo}`)}
                  name={company.name}
                  {...companyCursorHandlers}
                />
              </SwiperSlide>
            )})}
            </Swiper>
            <SwiperArrowsAndProgress>
              <div />
              <div className="swiper-button-prev"><LuArrowLeftCircle style={{ color: `${colors.red}`, fontSize: '25px'}} /></div>
              <div className="swiper-button-next"><LuArrowRightCircle style={{ color: `${colors.red}`, fontSize: '25px'}} /></div>
              <div />
            </SwiperArrowsAndProgress>
          </SwiperContainer>
          <DescriptionContainer>
            <StartupContentContainer company={company} />
          </DescriptionContainer>
        </PresentCohortContainer>
        <PresentMobileCohortContainer key={semester}>
          <Swiper
          style={{ height: '72vh'}}
          direction={'vertical'}
          modules={[Navigation]}
          spaceBetween={25}
          navigation={{
            nextEl: '.swiper-mobile-button-next',
            prevEl: '.swiper-mobile-button-prev',
          }}
          className="mySwiper">
          {companies.map((company) => {
            return (
            <SwiperSlide key={company.name} style={{ height: 'auto', display: 'flex', justifyContent: 'center'}}>
              <InnerSCContainerMobile>
                <TitleRowMobile>
                  <LogoMobile src={require(`../../assets/logos/${company.logo}`)}/>
                  <StartupTitleMobile style={{ marginBottom: '0.1em' }}> 
                    {company.name}
                  </StartupTitleMobile>
                </TitleRowMobile>
                <StartupContentMobileContainer company={company} />
              </InnerSCContainerMobile>
            </SwiperSlide>
          )})}
          </Swiper>
          <SwiperArrowsAndProgressTablet>
            <div />
            <div className="swiper-mobile-button-prev"><LuArrowUpCircle style={{ color: `${colors.red}`, fontSize: '25px'}} /></div>
            <div className="swiper-mobile-button-next"><LuArrowDownCircle style={{ color: `${colors.red}`, fontSize: '25px'}} /></div>
            <div />
          </SwiperArrowsAndProgressTablet>
        </PresentMobileCohortContainer>
        </>
      ))}
    </SectionContainer>
  );
}

export default CurrentComp;