import tipx from './logos/tipx.png';
import tuktuk from './logos/tuktuk.png';
import cornerstone from './logos/cornerstone.png';
import root from './logos/root.png';
import alois from './logos/alois.png';
import gazelle from './logos/gazelle.png';
import populus from './logos/populus.png';
import scraps from './logos/scraps.png';
import cacao from './logos/cacao.png';
import locol from './logos/locol.png';
import sensibed from './logos/sensibed.png';
import flightdeck from './logos/flightdeck.png';

// TODO missing Three0
// TODO add founders for recent cohort

const currentPortfolioInfo = [
      {
        name: "CURA",
        logo: "Cura_Logo.png",
        description: 'Cura is an AI-powered app providing tailored support for family caregivers of mentally ill.'
      },
      {
        name: "Resia",
        logo: "Resia_Logo.png",
        description: 'Resia is revolutionizing the $550B AEC industry with AI-driven 3D modeling and real-time cost estimation.',
      },
      {
        name: "Livegate",
        logo: "Livegate_Logo_Light.png",
        description: `Livegate is a live communications platform that lets people chat about sporting events as they're happening in real time.`,
      },
      {
        name: "Suits",
        logo: "Suits_Logo.png",
        description: 'Suits helps users join, create, and claim class action settlements.'
      },
      {
        name: "cacao.tax",
        logo: "cacao.png",
        description: 'cacao.tax is a duty-free marketplace for international travelers.'
      },
]

export default currentPortfolioInfo;